import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import TypedJS from 'typed-react-component';
import styles from './index.module.css';
import SEO from "../components/seo";

const typedProps = {
    style: { fontSize: '22px', fontWeight: '500' },
    typings: [
        'Full Stack Developer',
        'UI/UX Designer',
        'Amateur footballer ⚽️',
        'Cat photographer 🐱'
    ],
    loop: true,
    typeSpeed: 35,
    delay: 3000,
    startDelay: 1000,
    blinkerDelay: 400
};

export default () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                }
            }
            meImage: file(relativePath: { eq: "memoji.png" }) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            githubImage: file(relativePath: { eq: "github.png" }) {
                childImageSharp {
                    fixed(width: 30, height: 30) {
                        ...GatsbyImageSharpFixed_tracedSVG
                    }
                }
            }
            linkedinImage: file(relativePath: { eq: "linkedin.png" }) {
                childImageSharp {
                    fixed(width: 30, height: 30) {
                        ...GatsbyImageSharpFixed_tracedSVG
                    }
                }
            }
        }
    `);

    return (
        <div style={{ height: '100%' }}>
            <Layout showHeader={false}>
                <SEO title="" />
                <div className={styles.row} style={{ alignItems: 'center' }}>
                    <div className={styles.item}>
                        <Img style={{ maxWidth: '500px', width: '80%', margin: '0 auto' }} fluid={data.meImage.childImageSharp.fluid} />
                    </div>
                    <div className={styles.item}>
                        <h1 style={{ fontSize: '50px' }}>
                            Dylan <br />
                            van den Brink
                        </h1>
                        <TypedJS {...typedProps} />
                        <div className="row" style={{ marginTop: '33.5px' }}>
                            <a href="https://github.com/dylanvdbrink/" target="_blank" rel="noopener noreferrer"><Img fixed={data.githubImage.childImageSharp.fixed} /></a>
                            <a href="https://linkedin.com/in/dylanvandenbrink" target="_blank" rel="noopener noreferrer"><Img fixed={data.linkedinImage.childImageSharp.fixed} /></a>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

